<template>
  <div>
    <div>
      <time-left />
      <div class="confirm-header">
        <iq-title :class="{ desktopHeader: !isMobile, mobileHeader: isMobile }">
          Заполните данные по авто:
        </iq-title>
      </div>
      <el-col :span="24">
        <el-form
          ref="ruleForm"
          style="margin-top: 16px"
          :model="ruleForm"
          :rules="rules"
          class="drivers-slots-form"
          label-position="top"
        >
          <iq-autocomplete-form
            v-model="ruleForm.plate_truck"
            size="fullWidth"
            placeholder="Номер авто полностью с регионом"
            label="Введите номер авто полностью с регионом:"
            prop="plate_truck"
            :options="querySearchAuto"
            @focus="$refs.ruleForm.clearValidate('plate_truck')"
          />
          <iq-autocomplete-form
            v-if="isStepTerminal"
            v-model="ruleForm.plate_trailer"
            size="fullWidth"
            placeholder="Номер прицепа полностью с регионом"
            label="Введите номер прицепа полностью с регионом:"
            prop="plate_trailer"
            :options="querySearchTrailer"
          />
          <iq-select-form
            v-if="isStepTerminal"
            v-model.number="ruleForm['truck_spindles_count']"
            input-width="fullWidth"
            :options="autoAxle"
            label="Общее количество осей авто с прицепом:"
          />
          <iq-autocomplete-form
            v-model="ruleForm.name"
            size="fullWidth"
            :options="querySearchName"
            placeholder="Введите имя и фамилию"
            label="Имя и фамилия водителя"
            prop="name"
            @focus="$refs.ruleForm.clearValidate('name')"
          />
          <iq-autocomplete-form
            v-model="ruleForm.phone"
            v-mask="'+7 ### ###-##-##'"
            size="fullWidth"
            :options="querySearchPhone"
            placeholder="Введите телефон водителя"
            label="Телефон водителя"
            prop="phone"
            @focus="$refs.ruleForm.clearValidate('phone')"
          />

          <el-form-item>
            <iq-button size="fullWidth" @onClick="submitForm('ruleForm')">
              Подтвердить выбор
            </iq-button>
          </el-form-item>
        </el-form>
      </el-col>

      <iq-button color="second" size="fullWidth" @onClick="$router.go(-1)">
        Назад
      </iq-button>
    </div>
  </div>
</template>

<script>
import { DATE_FORMAT } from '@/constants/date'
import {
  DR_FETCH_AUTOCOMLETE_DATA,
  DR_GET_AUTOCOMLETE_DATA,
  DR_GET_CURRENT_TIME,
  DR_GET_FULL_FORM_WITH_AUTO,
  DR_GET_RUN_TIMER,
  DR_GET_SLOAT_PRICE_DATA,
  DR_GET_TIMESLOT_LIST,
  DR_GET_UNLOAD_ID,
  DR_POST_AUTOCOMPLETE_DATA,
  DR_SET_FULL_FORM_WITH_AUTO,
} from '@/store/actions'
import { autoAxle } from '@/constants/auto-type'
import {
  getFormattedTzDate,
  getIsAndroid,
  getTerminalId,
  numAutoValidator,
  numTrailerValidator,
} from '@/core'
import { isArray } from 'lodash'
import { isObject } from 'element-ui/src/utils/types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IqAutocompleteForm from '../../ui/components/autocomplete/IqAutocompleteForm'
import IqButton from '../../ui/components/buttons/IqButton'
import IqSelectForm from '../../ui/components/select/IqSelectForm'
import IqTitle from '../../ui/components/typography/IqTitle'
import TimeLeft from '@/views/drivers-home/components/TimeLeft'
import _ from 'lodash/fp'

export default {
  name: 'ConfirmAddAuto',
  components: {
    TimeLeft,
    IqTitle,
    IqAutocompleteForm,
    IqButton,
    IqSelectForm,
  },
  data() {
    return {
      autoAxle,
      ruleForm: {
        timeslot_id: this.$route.params.id,
        submit: 1,
        name: '',
        phone: '',
        plate_trailer: null,
        plate_truck: null,
        truck_spindles_count: null,
      },
      rules: {
        plate_truck: [
          {
            required: true,
            message: 'Заполните номер авто',
            trigger: 'submit',
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (numAutoValidator(value)) {
                callback(
                  new Error(
                    'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
                  ),
                )
              }
              if (this.validatePass(rule, value)) {
                callback(new Error('На данное авто уже забронирован таймслот'))
              }

              return callback()
            },

            trigger: 'submit',
          },
        ],
        plate_trailer: [
          {
            required: true,
            message: 'Заполните номер прицепа',
            trigger: 'submit',
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (numTrailerValidator(value)) {
                callback(new Error('Формат номера должен быть "CT176976"'))
              }
              if (this.validatePass(rule, value)) {
                callback(new Error('На данное авто уже забронирован таймслот'))
              }

              return callback()
            },

            trigger: 'submit',
          },
        ],
        name: [
          {
            required: true,
            message: 'Введите имя',
            trigger: 'submit',
          },
          {
            min: 3,
            max: 100,
            message: 'Длина имени от 3 символов',
            trigger: 'submit',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Заполните телефон',
            trigger: 'submit',
          },
          {
            min: 16,
            max: 16,
            message: 'Заполните телефон полностью',
            trigger: 'submit',
          },
        ],
      },
      trailerFromState: [],
      autoFromState: [],
      nameFromState: [],
      phoneFromState: [],
    }
  },
  computed: {
    ...mapGetters({
      runTimer: DR_GET_RUN_TIMER,
      currentTimeState: DR_GET_CURRENT_TIME,
      autocompleteData: DR_GET_AUTOCOMLETE_DATA,
      formData: DR_GET_FULL_FORM_WITH_AUTO,
      timeslotsList: DR_GET_TIMESLOT_LIST,
      getTimeslot: DR_GET_SLOAT_PRICE_DATA,
      getUnloadId: DR_GET_UNLOAD_ID,
    }),
    isStepTerminal() {
      const { step } = this.getTimeslot[0]?.timeslot?.extras

      if (step) {
        return Object.keys(step).length !== 0
      }

      return false
    },

    isAndroid() {
      return getIsAndroid()
    },
  },

  watch: {
    runTimer: {
      immediate: true,
      handler(value) {
        if (value === false) {
          this.$router.go(-1)
        }
      },
    },
    formData: {
      immediate: true,
      handler(value) {
        let formFromState = value.filter(item => {
          if (String(item.timeslot_id) === String(this.ruleForm.timeslot_id)) {
            return item
          }
        })

        if (formFromState.length > 0) {
          this.ruleForm = {
            plate_truck: formFromState[0].plate_truck,
            plate_trailer: formFromState[0].plate_trailer,
            timeslot_id: formFromState[0].timeslot_id,
            submit: formFromState[0].submit,
            name: formFromState[0].name,
            phone: formFromState[0].phone,
            truck_spindles_count: formFromState[0].truck_spindles_count,
          }
        }
      },
    },
    currentTimeState(newVal) {
      if (newVal === 0) {
        this.$router.go(-1)
      }
    },
    autocompleteData: {
      immediate: true,
      handler(value) {
        let { plate_truck, plate_trailer } = value

        if (isObject(value)) {
          this.autoFromState = {
            value: plate_truck,
            link: plate_truck,
          }
          this.trailerFromState = {
            value: plate_trailer,
            link: plate_trailer,
          }
        }
        if (isArray(value)) {
          let autoFromStateArray = value.map(item => item.plate_truck)
          // по прицепам могут быть пустые, поэтому фильтруем
          let trailerFromStateArray = value
            .filter(item => item.plate_trailer)
            .map(item => item.plate_trailer)

          this.autoFromState = this.unique(autoFromStateArray).map(item => ({
            value: item,
            link: item,
          }))
          this.trailerFromState = this.unique(trailerFromStateArray).map(
            item => ({
              value: item,
              link: item,
            }),
          )
        }
      },
    },
    'ruleForm.plate_truck'(newVal) {
      this.nameFromState = []
      this.phoneFromState = []
      this.ruleForm.name = ''
      this.ruleForm.phone = ''
      this.autocompleteData?.map(item => {
        if (item.plate_truck === newVal) {
          this.nameFromState.push(item.name)
          this.phoneFromState.push(item.phone)
        }
      })

      this.nameFromState = this.unique(this.nameFromState).map(item => ({
        value: item,
        link: item,
      }))
      this.phoneFromState = this.unique(this.phoneFromState).map(item => ({
        value: item,
        link: item,
      }))
    },
  },
  mounted() {
    this.fetchAutocompleteData()
  },
  methods: {
    ...mapActions({
      fetchAutocompleteData: DR_FETCH_AUTOCOMLETE_DATA,
      postAutocompleteData: DR_POST_AUTOCOMPLETE_DATA,
    }),
    unique(arr) {
      let result = []

      for (let str of arr) {
        if (!result.includes(str)) {
          result.push(str)
        }
      }

      return result
    },

    validatePass(rule, value) {
      const truckStatuses = ['not_stated', 'parked_far', 'parked_close']
      const submitStatuses = ['manual', 'submitted']
      let valid = false

      this.formData.map(item => {
        if (
          item.plate_truck === value &&
          this.ruleForm.timeslot_id !== item.timeslot_id
        ) {
          valid = true
        }
      })
      // не даём взять таймслот, если на эту же машину:
      // - есть любой активный таймслот на этом же терминале
      // - есть активный таймслот на любом другом терминале на эту же дату
      let dublicateSlot = this.timeslotsList.filter(
        item =>
          item.plate_truck &&
          item.plate_truck.toUpperCase() === value.toUpperCase() &&
          truckStatuses.includes(item.truck_status) &&
          submitStatuses.includes(item.submit_status) &&
          (this.getUnloadId === item.unload_id ||
            getFormattedTzDate(item.time, DATE_FORMAT) ===
              getFormattedTzDate(this.getTimeslot[0].time, DATE_FORMAT)),
      )

      if (dublicateSlot.length > 0) {
        valid = true
      }

      return valid
    },
    ...mapMutations({ updateFormData: DR_SET_FULL_FORM_WITH_AUTO }),
    changeNumberAuto() {
      this.showSecondStageForm = false
    },
    querySearchAuto(queryString, cb) {
      const autoNumbers = this.autoFromState?.filter(
        item => !_.find(this.formData, { plate_truck: item.value }),
      )
      const results = queryString
        ? autoNumbers.filter(this.createFilter(queryString))
        : autoNumbers

      cb(results)
    },
    querySearchTrailer(queryString, cb) {
      const trailerNumbers = this.trailerFromState?.filter(
        item => !_.find(this.formData, { plate_trailer: item.value }),
      )

      const results = queryString
        ? trailerNumbers.filter(this.createFilter(queryString))
        : trailerNumbers

      cb(results)
    },
    querySearchName(queryString, cb) {
      var name = this.nameFromState
      var results = queryString
        ? name.filter(this.createFilter(queryString))
        : name

      cb(results)
    },

    querySearchPhone(queryString, cb) {
      var phones = this.phoneFromState
      var results = queryString
        ? phones.filter(this.createFilter(queryString))
        : phones

      cb(results)
    },
    createFilter(queryString) {
      return link =>
        link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.postAutocompleteData({
            ...this.ruleForm,
            unload_id: getTerminalId(),
          })
          this.updateFormData(this.ruleForm)
          this.$router.go(-1)
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="sass">
$greyBackColor: #E8E8E8
$blueBackColor: #C1E1EC
$mainFont: #333333
$greenColor: #008400
$redColor: red

.drivers-slots-form
  background: white
  font-weight: normal
  font-size: $fs-14
  line-height: $lh-16
  position: relative
  .el-form-item
    &__label
      text-align: left
      line-height: 22px
      font-weight: bold
      width: 100%
      padding: 0 !important
  .el-date-editor
    width: 100% !important
  +to(550px)
    .el-form-item__label
      min-width: 300px!important
  .el-form-item__content
    line-height: 32px
  .el-select
    width: 100%
  .el-input__icon:before
    font-size: 14px
#app
  .confirm-form
    .el-form-item__label
      width: auto!important
    +to(550px)
      .el-form-item__label
        width: 100% !important
      .el-form-item__content
        margin-left: 0 !important
.drivers-slot-btn
  background: $color-blue-light
  width: 100%
  padding: 12px 0px
  height: 45px
.drivers-main-container
  background: white
  margin-top: 40px
  padding: 15px
  max-height: 80vh
  overflow: scroll
  min-width: 320px
  &.android
    margin-top: 0
    padding: 40px 15px 80px
    max-height: unset
  .el-form-item__label
    width: 100% !important
    &:before
      font-size: 14px
  i
    @media only screen and (max-width: 550px)
      &:before
        font-size: 14px!important

.el-message.driver-message
  top: 50px!important
  min-width: 90%!important
  margin: 50px
  left: 45%!important
  i
    @media only screen and (max-width: 550px)
      &:before
        font-size: 28px!important
  +to(768px)
    top: 50px!important
    min-width: 90%!important
    margin: 50px
    left: 43%!important
  +to(390px)
    top: 50px!important
    min-width: 90%!important
    margin: 50px
    duration: 30000!important
    left: 37%!important
.confirm-add-auto
  .el-autocomplete
    width: 100%
  .el-select
    width: 100%
  .auto-number-confirm
    display: flex
    justify-content: space-between
    align-items: center
  .drivers-slots-form
    background: white
    font-weight: normal
    font-size: $fs-14
    line-height: $lh-16
    position: relative
    .el-form-item
      &__label
        text-align: left
        line-height: 22px
        font-weight: bold
        width: 100%
        padding: 0 !important
    .el-date-editor
      width: 100% !important
    +to(550px)
      .el-form-item__label
        min-width: 300px!important
    .el-form-item__content
      line-height: 32px
    .el-select
      width: 100%
    .el-input__icon:before
      font-size: 14px
  .line
    display: flex
    justify-content: center
    align-items: center
i
  @media only screen and (max-width: 550px)
    &:before
      font-size: 14px!important
.no-quota
  display: block
  margin-top: 12px
  padding: 8px
  text-align: center
  background: rgba(239, 0, 9, 0.29)
  color: white
  border-radius: 4px
  margin-bottom: 24px
.input-quota
  margin-bottom: 10px
  border-radius: 4px
.confirm-modal
  width: 400px!important
  padding: 8px 8px 24px 8px
  @media only screen and (max-width: 550px)
    width: 50%
  label
    font-size: 13px
  .el-message-box__message
    font-size: 16px
  .confirmDelete
    background: $btn-orange
    border-color: $btn-orange
  .el-button
    font-size: 14px
.confirm-title
  text-align: center
  margin: 8px
  margin-bottom: 16px
.timer-slots
  margin-bottom: 16px
  display: flex
  justify-content: space-between
  align-items: center
  > span
    display: block
    color: $btn-orange
    font-weight: bold
</style>
